import React  from 'react';
import { FaLess, FaSass, FaSourcetree } from "react-icons/fa";
import {
  SiHtml5,
  SiCss3,
  SiTailwindcss,
  SiBootstrap,
  SiReact,
  SiTypescript,
  SiAngular,
  SiPhp,
  SiWordpress,
  SiGithub,
  SiBitbucket,
  SiJira,
  SiAdobexd,
  SiFigma,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiVisualstudio,
  SiVisualstudiocode,
  SiMaterialUi,
  SiMaterialdesign,
  SiFontawesome,
} from "react-icons/si";

/* Tools Items List */
export const toolsList = [
  { name: 'HTML5', icon: <SiHtml5 /> },
  { name: 'CSS3', icon: <SiCss3 /> },
  { name: 'Less', icon: <FaLess /> },
  { name: 'Sass/Scss', icon: <FaSass /> },
  { name: 'Tailwind CSS', icon: <SiTailwindcss /> },
  { name: 'Bootstrap', icon: <SiBootstrap /> },
  { name: 'Material UI', icon: <SiMaterialUi /> },
  { name: 'Material Design', icon: <SiMaterialdesign /> },
  { name: 'Figma', icon: <SiFigma /> },
  { name: 'Adobe Xd', icon: <SiAdobexd /> },
  { name: 'FontAwesome', icon: <SiFontawesome /> },
  { name: 'ReactJS', icon: <SiReact /> },
  { name: 'TypeScript', icon: <SiTypescript /> },
  { name: 'Angular', icon: <SiAngular /> },
  { name: 'PHP', icon: <SiPhp /> },
  { name: 'WordPress', icon: <SiWordpress /> },
  { name: 'GitHub', icon: <SiGithub /> },
  { name: 'Bitbucket', icon: <SiBitbucket /> },
  { name: 'SourceTree', icon: <FaSourcetree /> },
  { name: 'Jira', icon: <SiJira /> },
  { name: 'Adobe Photoshop', icon: <SiAdobephotoshop /> },
  { name: 'Adobe Illustrator', icon: <SiAdobeillustrator /> },
  { name: 'Visual Studio', icon: <SiVisualstudio /> },
  { name: 'Visual Studio Code', icon: <SiVisualstudiocode /> }
];

export default toolsList;
