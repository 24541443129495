import React  from 'react';
import { SiFigma, SiAdobexd, SiSass, SiReact, SiAngular, SiSvelte } from "react-icons/si";

/* Homepage Portfolio Items List */
export const homepagePortfolioImages = [
  {
    src: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-colors-light_h5JDAszZAz.jpg",
    title: "Color Palette (Light Mode)",
    alt: "LC Color Palette Light"
  },
  {
    src: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-login-dark_VyU87u6P4a.jpg",
    title: "Login Page (Dark Mode)",
    alt: "LC Login Page Dark"
  },
  {
    src: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-cases_qQI8jR5wdk.jpg",
    title: "Cases Grid Table",
    alt: "LC Cases Grid Table"
  }
];

/* Portfolio Items List */
export const portfolioImages = [
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-typography-dark_nLtY5foXUm.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-typography-dark_4rtik7J18g.jpg",
    title: "Typography (Dark Mode)",
    alt: "LC Typography Dark",
    width: 1440,
    height: 1000,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-typography-light_pNYUmauaJ.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-typography-light_o6SGaAl-U.jpg",
    title: "Typography (Light Mode)",
    alt: "LC Typography Light",
    width: 1440,
    height: 1000,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-colors-dark_Q3LRpGThDU.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-colors-dark_vGtmjYYAv.jpg",
    title: "Color Palette (Dark Mode)",
    alt: "LC Color Palette Dark",
    width: 1440,
    height: 1000,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-colors-light_CXjHNYRWR.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-colors-light_h5JDAszZAz.jpg",
    title: "Color Palette (Light Mode)",
    alt: "LC Color Palette Light",
    width: 1440,
    height: 1000,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-login-dark_PYL-GnOZD.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-login-dark_VyU87u6P4a.jpg",
    title: "Login Page (Dark Mode)",
    alt: "LC Login Page Dark",
    width: 1920,
    height: 1080,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      },
      {
        name: 'ReactJS',
        icon: <SiReact />
      },
      {
        name: 'Sass/Scss',
        icon: <SiSass />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-login-light_QMKr-HpiJ.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-login-light_74ZxtgcBNz.jpg",
    title: "Login Page (Light Mode)",
    alt: "LC Login Page Light",
    width: 1920,
    height: 1080,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      },
      {
        name: 'ReactJS',
        icon: <SiReact />
      },
      {
        name: 'Sass/Scss',
        icon: <SiSass />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-dashboard_P-tuFhdTV5.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-dashboard_HxGzSCU2M.jpg",
    title: "Dashboard",
    alt: "LC Dashboard",
    width: 1513,
    height: 2555,
    tools: [
      {
        name: 'Adobe Xd',
        icon: <SiAdobexd />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-cases_4syq9HGh6.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-cases_qQI8jR5wdk.jpg",
    title: "Cases Grid Table",
    alt: "LC Cases Grid Table",
    width: 1920,
    height: 1080,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      },
      {
        name: 'Angular',
        icon: <SiAngular />
      },
      {
        name: 'Sass/Scss',
        icon: <SiSass />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-login__VFUZTj5RH.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-login_JFmSey0X4.jpg",
    title: "Login Page",
    alt: "LC Login 2",
    width: 1920,
    height: 1080,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      },
      {
        name: 'Angular',
        icon: <SiAngular />
      },
      {
        name: 'Sass/Scss',
        icon: <SiSass />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-styleguide_eSU42P_th.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-styleguide_lA4FBZmgv.jpg",
    title: "Styleguide",
    alt: "LC Styleguide",
    width: 2000,
    height: 2000,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />
      }
    ]
  },
  {
    original: "https://ik.imagekit.io/laurdesign/portfolio/original/lc-login-2_pi4RU12vyj.jpg",
    thumbnail: "https://ik.imagekit.io/laurdesign/portfolio/thumbnails/lc-thumb-login-2_oACUAOxFZY.jpg",
    title: "Login Page",
    alt: "LC Login 1",
    width: 1366,
    height: 768,
    tools: [
      {
        name: 'Figma',
        icon: <SiFigma />,
      },
      {
        name: 'Svelte',
        icon: <SiSvelte />
      },
      {
        name: 'Sass/Scss',
        icon: <SiSass />
      }
    ]
  }
];

export default portfolioImages;
