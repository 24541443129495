import React  from 'react';
import { FiLayers, FiMonitor, FiSmartphone, FiActivity, FiCast, FiCode } from "react-icons/fi";

/* Services Items List */
export const servicesList = [
  {
    icon: <FiMonitor />,
    title: 'UI Design',
    description: 'I design and implement products ideas that are useful, easy to use, user friendly and delightful to interact with.'
  },
  {
    icon: <FiCast />,
    title: 'Cross-Platform Implementation',
    description: 'Facilitate developers to have complete mind on one coding base that can run on multiple mobile platforms.'
  },
  {
    icon: <FiLayers />,
    title: 'Mockups & Wireframes',
    description: 'I bring together form and the function to create something that looks good and performs brilliantly. Simple.'
  },
  {
    icon: <FiSmartphone />,
    title: 'Mobile App Design',
    description: 'I can help you build the right user flow, creating powerful opportunities and providing a competitive advantage.'
  },
  {
    icon: <FiCode />,
    title: 'Website Development',
    description: 'I help brands effectively, efficiently and emotionally deliver their stories to their audiences through full-funnel marketing programs.'
  },
  {
    icon: <FiActivity />,
    title: 'UI Consulting',
    description: 'I rate how well your site or application adheres to best practices and UI principles to make the findings actionable.'
  },
];
  
  export default servicesList;